<template>
  <v-container 
    :class="isInsuredTitleRecords ? noPaddingStyle : standardPaddingStyle"
    >
    <v-row>
      <v-col
        v-if="title !== null"
        class="pb-0">
        <v-card-title
          class="pa-0"
          style="display: flex; flex-wrap: nowrap; align-items: baseline;"
        >
          <v-img
            v-if="title"
            src="@/assets/img/title-item.png"
            max-width="10px"
            max-height="20px"
          ></v-img>
          <span class="font-weight-bold ml-2" style="font-size: large;">
            {{ title }}
          </span>
        </v-card-title>
        <span if="explanation">
          {{ explanation }}
        </span>
      </v-col>
      <v-container class="ma-0 pa-0">
        <table
          class="ma-0"
          style="width: 100%; border-spacing: 5px; font-size: 14px;"
        >
          <tbody
            v-for="record in records" :key="record.key"
          >
            <tr>
              <!-- 項目に下線を引き、ボタンクリックで項目説明のダイアログを表示する機能、ロジックのみ残す -->
              <td
                v-if="isExplanationItem(record.key)"
                class="text-left base_td_category_text px-2"
                style="text-decoration: underline; cursor: pointer;"
                @click="toggleShowItemExplanation(record.key)"
                :colspan="getColspan(record.value)"
              >
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(
                    $t(
                      `header.reviewContractTable.${contentPageName}.${record.key}`
                    )
                  )"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>
              <td
                v-else
                class="text-left base_td_category_text px-2 py-2"
                :colspan="getColspan(record.value)"
              >
                <div
                  v-if="
                    $te(
                      `header.reviewContractTable.${contentPageName}.${record.key}`
                    )
                  "
                >
                  <p
                    class="ma-0"
                    style="word-wrap: break-word;"
                    v-for="(message, index) in splitMessageLine(
                      $t(
                        `header.reviewContractTable.${contentPageName}.${record.key}`
                      )
                    )"
                    :key="index"
                  >
                    {{ message }}
                  </p>
                </div>
                <div v-else>
                  <p
                    class="ma-0"
                    style="word-wrap: break-word;"
                    v-for="(message, index) in splitMessageLine(record.key)"
                    :key="index"
                  >
                    {{ message }}
                  </p>
                </div>
              </td>
              <td
                v-if="!record.titleLineFlg"
                class="pl-2 py-2"
                style="border: solid 1px #cccccc;"
              >
                <p
                  class="ma-0"
                  style="word-wrap: break-word;"
                  v-for="(message, index) in splitMessageLine(record.value)"
                  :key="index"
                >
                  {{ message }}
                </p>
              </td>
            </tr>
            <!-- お申込み内容確認画面1/4 記名被保険者の場合 -->
            <tr
              v-if="isInsuredTitleRecords"
              class="ma-0 pa-0"
            >
              <td
                :colspan="2"
                style="font-size: 16px;"
              >
                <p
                  class="text-left ma-0 pa-0"
                >
                  {{ noticeText }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-container>
    </v-row>

    <ItemExplanationDialog
      :maxWidth="320"
      :showDialog="showItemExplanation"
      :title="explanationItemTitle"
      :text="explanationItemText"
      :negativeButtonTitle="$t('button.close')"
      :onClickNegativeButton="toggleShowItemExplanation"
    />
  </v-container>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
import ItemExplanationDialog from '@/components/organisms/contractor/ItemExplanationDialog.vue';

export default {
  components: {
    ItemExplanationDialog,
  },
  props: {
    title: String,
    explanation: String,
    noticeText: String,
    contentPageName: String,
    records: Array,
    isInsuredTitleRecords: Boolean,
  },
  data: () => ({
    showItemExplanation: false,
    explanationItemText: '',
    explanationItemTitle: '',
    standardPaddingStyle: ['pt-6 px-3 pb-0'],
    noPaddingStyle: ['pt-0 px-3 pb-3'],
  }),
  methods: {
    isExplanationItem(key) {
      return this.$te(this.targetI18n(key));
    },
    targetI18n(key) {
      return `tableItems.reviewContract.${this.contentPageName}.explanationItems.${key}`;
    },
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    getColspan(value) {
      return value !== '' ? 1 : 2;
    },
    toggleShowItemExplanation(key) {
      this.showItemExplanation = !this.showItemExplanation;

      if (this.showItemExplanation) {
        this.explanationItemTitle = this.$t(
          `header.reviewContractTable.${this.contentPageName}.${key}`
        );
        this.explanationItemText = this.$t(this.targetI18n(key));
      } else {
        this.explanationItemTitle = '';
        this.explanationItemText = '';
      }
    },
  },
};
</script>
<style>
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
